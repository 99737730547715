<template>
  <Toast />
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">

        <FullCalendar :options="calendarOptions"></FullCalendar>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ScheduleService from "@/service/ScheduleService";
import FullCalendar from "@fullcalendar/vue3";
import timeGridPlugin from '@fullcalendar/timegrid';
import brLocale from '@fullcalendar/core/locales/pt-br';
import _ from "lodash"

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      schedules: null,
      schedule: {},
      selectedSchedules: null,
      scheduleService: null,
      loader: null,

      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        initialView: 'dayGridMonth',
        timeZone: 'America/Sao_Paulo',
        locale: brLocale,
        editable: false,
        selectable:true,
        selectMirror: true,
        dayMaxEvents: true
      }
    }
  },
  created() {
    this.scheduleService = new ScheduleService();
  },
  async mounted() {
    await this.verifyRole();
  },
  methods: {

    async findAll() {
      this.loader = this.$loading.show();
      this.calendarOptions.events = []
      const response = await this.scheduleService.findAll(parseInt(JSON.parse(localStorage.getItem("user")).id));
      if(response.status === 200) {
        for (const event of response.data.content) {
          const v = {
            title: `${event.schedulerType} - ${event.contact.name}`,
            start: moment(event.startDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD hh:mm'),
            end: moment(event.endDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD hh:mm'),
            url: `/admin/agendamento-status?id=${event.id}`,
          }
          this.verifyScheduleType(event, v);
          this.calendarOptions.events.push(v);
        }
      }
      this.loader.hide();
    },

    async findAllClinical() {
      this.loader = this.$loading.show();
      this.calendarOptions.events = []
      const response = await this.scheduleService.findAllClinical();
      if(response.status === 200) {
        for (const event of response.data.content) {
          const v = {
            title: `${event.schedulerType} - ${event.contact.name}`,
            start: moment(event.startDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD hh:mm'),
            end: moment(event.endDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD hh:mm'),
            url: `/admin/agendamento-status?id=${event.id}`,
          }
          this.verifyScheduleType(event, v);

          this.calendarOptions.events.push(v);
        }
      }
      this.loader.hide();
    },

    verifyScheduleType(event, v) {
      if(event.schedulerType === 'CONCLUIDO') {
        v.backgroundColor = 'green';
        v.borderColor = 'green';
      }
      if(event.schedulerType === 'CANCELADO') {
        v.backgroundColor = 'red';
        v.fontColor = 'red';
        v.borderColor = 'red';
      }
      if(event.schedulerType === 'CONSULTA') {
        v.color = 'purple'
      }
      if(event.schedulerType === 'RETORNO') {
        v.color = 'blue'
      }

    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },

    async verifyRole() {
      const roles = JSON.parse(localStorage.getItem("user")).roles;

      const rolesString = [];
      roles.forEach((role) => {
        rolesString.push(role.name);
      });

      if(_.includes(rolesString, 'ROLE_ADMIN') || _.includes(rolesString, 'ROLE_ATENDIMENTO')){
        await this.findAllClinical();
      } else if(_.includes(rolesString, 'ROLE_ESPECIALISTA') && !_.includes(rolesString, 'ROLE_ADMIN')) {
        await this.findAll();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.schedule-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-true {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-false {
    background: #FFCDD2;
    color: #C63737;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.fc-header-toolbar) {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
